import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import CelloLoader from "../components/CelloLoader";
import MenuHamburger from "../components/MenuHamburger"
import ConcertItem from "../components/ConcertHome"
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet'

export const Home = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [addCelloClass, setAddCelloClass] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPortrait, setShowPortrait] = useState(false);
  const [language, setLanguage] = useState("en");
  const [showMobile, setShowMobile] = useState(false);
  const [isFooterBottomZero, setIsFooterBottomZero] = useState(false);

    const imageClass = showPortrait ? 'background-image2' : 'background-image';

    const setFooterClass = (bottomZero) => {
      setIsFooterBottomZero(bottomZero);
    };


    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 530) {
          setShowMobile(false);
        } else {
          setShowMobile(true);
        }
      };

      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);


  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
      setAddCelloClass(true);
    }, 2300);

    // Set another timeout to hide the loader after 4.5 seconds
    const hideLoaderTimeout = setTimeout(() => {
      setHideLoader(true);
    }, 2800);

    return () => {
      clearTimeout(loaderTimeout);
      clearTimeout(hideLoaderTimeout);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowPortrait(false);
      } else {
        setShowPortrait(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Tatu Kauppinen | Cellist</title>
      <meta name="description" content="Tatu Kauppinen" />
    </Helmet>
    <div className="home-container">
      {!hideLoader && <CelloLoader additionalClass={addCelloClass ? "hidden" : "visible"} />}
      {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} language={language}/>}
      {!showMenuHamburger && <Header language={language}/> }
      <div className="image-wrapper">
      {!showMobile ? (
            <img className={imageClass} loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5460.jpg" />
              ) : (
            <img className="background-image3" loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5467.jpg" />
      )}
      </div>
      <div className="quote-wrapper">
        <blockquote className="home-quote">“Lorem ipsum dolor sit amet, consectetur adipiscing elit.”</blockquote>
        <blockquote className="quoter"> Boethius </blockquote>
      </div>
      <div className="home-concerts-container">
        <ConcertItem day="19" month="JAN" city="Berlin" place="Philharmonie Berlin"/>
        <ConcertItem day="20" month="FEB" city="Lappeenranta" place="St. Mary’s Church of Lappee"/>
        <a className="home-upcoming-events-container" href='/concerts'>
          <div className="home-upcoming-events margin-small">All Upcoming Concerts</div>
          <svg className="home-upcoming-events" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 6.5L1 12" stroke="currentColor" strokeWidth="1.5"/>
          </svg>
        </a>
      </div>
      <div className="home-concerts-container-sizes">
          <div className="size-upcoming-concerts-container">
            <a className="home-upcoming-events-container" href='/concerts'>
              <div className="home-upcoming-events margin-small">All Upcoming Concerts</div>
              <svg className="home-upcoming-events" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 6.5L1 12" stroke="currentColor" strokeWidth="1.5"/>
              </svg>
            </a>
          </div>
          <div className="concerts-container-sizes">
            <ConcertItem day="19" month="JAN" city="Berlin" place="Philharmonie Berlin, Chamber Music Hall" />
            <ConcertItem day="20" month="FEB" city="Lappeenranta" place="St. Mary’s Church of Lappee" />
          </div>
      </div>
      {hideLoader && <Footer  setFooterClass={setFooterClass}/>}
    </div>
    </>
  );
};

export default Home;
